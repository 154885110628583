<template>
    <div class="pb-13">
      <!-- tabelas dos visitantes -->
      <v-card id="visitantes">
        <TablePresents
          :headers="headersVisitas"
          :items="getVisitas"
          :actions="false"
        />
      </v-card>
      <!-- tabelas dos veiculos -->
      <v-card id="veiculos" class="my-2">
        <TableVeiculos
          :headers="headersVeiculos"
          :items="getVeiculos"
          :actions="false"
        />
      </v-card>
      <!-- tabela das frotas -->
      <v-card id="frotas" class="my-2">
        <TableFrotaMulti
          :headers="headersFleetsMulti"
          :items="getFleetsMultiStarted"
          :actions="false"
        />
      </v-card>
      <v-card id="frotas-antiga">
        <TableFrotaSimples
          :headers="headersFleetsSimple"
          :items="getFleetStarted"
          :actions="false"
        />
      </v-card>
    </div>
  </template>
  
  <script>
  import TablePresents from "@/components/Visitas/TablePresents";
  import TableVeiculos from "@/components/Veiculo/TableReportVeiculos";
  import TableFrotaMulti from "@/components/Frota/TableFleetMulti";
  import TableFrotaSimples from "@/components/Frota/TableFleetSimple";
  import { mapGetters } from "vuex";
  export default {
    name: "EfetivoHome",
    components: {
      TablePresents,
      TableVeiculos,
      TableFrotaMulti,
      TableFrotaSimples,
    },
    computed: {
      ...mapGetters([
        "getVisitas",
        "getVeiculos",
        "getFleetsMultiStarted",
        "getFleetStarted",
      ]),
    },
    data() {
      return {
        headersVisitas: [
          { text: "Visitante", value: "visitanteName" },
          { text: "Visitado", value: "visitadoName" },
          { text: "Descrição", value: "description" },
          { text: "Entrada", value: "createdAt" },
          { text: "Grupo", value: "groupName" },
        ],
        headersVeiculos: [
          {text: "Imagens Iniciais", value: "picturesEnter", sortable: false, filterable: false, align: "center", width: 70, },
          { text: "Placa", value: "placa" },
          { text: "Descrição", value: "description" },
          { text: "Entrada", value: "createdAt" },
          { text: "Visita", filterable: false, sortable: false, value: "visitaId", width: 450, },
          { text: "Grupo", value: "groupName" },
        ],
        headersFleetsMulti: [
          { text: "Imagens Iniciais", value: "pictures", align:'center', sortable: false, filterable: false, width: 70 },
          { text: "Motoristas Iniciais", value: "drivers", sortable: false, filterable: false, width: 300 },
          { text: "Veículos Iniciais", value: "fleetVehicles", sortable: false, filterable: false, width: 300 },
          { text: "Descrição Inicial", value: "description" },
          { text: "Início", value: "createdAt" },
          { text: "Km Inicial", value: "kmStart" },
          { text: "ID Cliente", value: "clientId" },
        ],
        headersFleetsSimple: [
          { text: "Imagens Iniciais", value: "picturesStart", align:'center', sortable: false, filterable: false, width: 70 },
          { text: "Motorista", value: "driver", width: 300 },
          { text: "Veículo", value: "vehicle", width: 300 },
          { text: "Descrição", value: "description" },
          { text: "Início", value: "createdAt" },
          { text: "Km Inicial", value: "kmStart" },
          { text: "ID Cliente", value: "clientId" },
        ],
      };
    },
    created() {
      this.$store.dispatch("loadVisitas");
      this.$store.dispatch("loadVeiculos2");
      this.$store.dispatch("loadFleetsMultiStarted");
      this.$store.dispatch("loadFleetsStarted2");
    },
  };
  </script>
  
  <style>
  </style>